import { Typography, Box } from '@mui/material'
import { TitleProps } from '../types/componentTypes'

const Title = (props: TitleProps): JSX.Element => {
  const { label, subtitle, extra } = props
  if (subtitle !== undefined) {
    if (extra !== undefined) {
      return (
        <Box sx={{ color: 'secondary.dark' }}>
          <Box sx={{ display: 'flex', alignItems: 'baseline', marginBottom: -10 }}>
            <Typography variant='h4' style={{ paddingRight: 10 }}>{label}</Typography>
            <Typography variant='h6'>{extra}</Typography>
          </Box>
          <Typography variant='h4'>{subtitle}</Typography>
        </Box>
      )
    }
    return (
      <Box sx={{ display: 'flex', alignItems: 'baseline', color: 'secondary.dark', flexWrap: 'wrap' }}>
        <Typography variant='h4' style={{ paddingRight: 10 }}>{label}</Typography>
        <Typography variant='h6'>{subtitle}</Typography>
      </Box>
    )
  }
  return (
    <Box sx={{ color: 'secondary.dark' }}>
      <Typography variant='h4'>{label}</Typography>
    </Box>
  )
}

export default Title
