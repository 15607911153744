import { RecoilState, RecoilValueReadOnly, useRecoilValue, useSetRecoilState } from 'recoil'
// import { AmalfiSelect } from '@amalfi-analytics/components'
import AmalfiSelect from '../../filters/AmalfiSelector'
import { SelectChangeEvent } from '@mui/material'
import useDictionary from '../../utils/useDictionary'

interface IrisSelectAtomProps {
  name: string
  value: string
  options: Array<{ value: string, name: string }>
  dictName?: string
  dictAppId?: string
}

export interface IrisSelectProps {
  atom: RecoilState<IrisSelectAtomProps>
  selector: RecoilValueReadOnly<IrisSelectAtomProps | undefined>
  disabled?: boolean
}

const IrisSelector = ({ atom, selector }: IrisSelectProps): JSX.Element => {
  const setState = useSetRecoilState(atom)
  const state = useRecoilValue(selector)

  const handleChange = (event: SelectChangeEvent): void => {
    if (state !== undefined) {
      setState({ ...state, value: event.target.value })
    }
  }

  const { loading, searchInDictionary } = useDictionary(state?.dictName, state?.dictAppId)

  if (state === null || state === undefined || loading) {
    return <></>
  }

  // Translate component
  const label = searchInDictionary(state.name)
  const itemsData = state.options.map(item => { return { ...item, label: (searchInDictionary(item.name)) } })

  // Call UI element
  return <AmalfiSelect itemsData={itemsData} label={label} value={state.value} handleChange={handleChange} />
}

export default IrisSelector
